import React from "react";
import WolfmailComponent from "./components/WolfmailComponent";
import Order from "./components/order";
import { Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Routes>
      <Route index element={<WolfmailComponent />} />
      <Route path="/" element={<WolfmailComponent />} />
      <Route path="/" element={<WolfmailComponent />} />
      <Route path="/order" element={<Order />} />
    </Routes>
  );
}
