import React, { useState } from "react";
import logo from "../assets/favicon-32x32.png";

import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-nav shadow-lg sticky top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" />
          <a href="/" className="text-2xl font-bold text-white ml-2">
            <span className="font-light">Wolf</span>Mail
          </a>
        </div>

        <div className="hidden md:flex">
          <a href="/" className="text-secondary hover:text-primary mx-2">
            Home
          </a>
          <a className="text-secondary hover:text-primary mx-2">
            <ScrollLink
              to="features"
              className="text-secondary hover:text-primary mx-2 cursor-pointer"
            >
              Features
            </ScrollLink>
          </a>
          <a className="text-secondary hover:text-primary mx-2">
            <ScrollLink
              to="pricing"
              className="text-secondary hover:text-primary mx-2 cursor-pointer "
            >
              Pricing
            </ScrollLink>
          </a>
          <a className="text-secondary hover:text-primary mx-2">
            <ScrollLink
              to="contact"
              className="text-secondary hover:text-primary mx-2 cursor-pointer"
            >
              Contact
            </ScrollLink>
          </a>
        </div>

        <div className="md:hidden z-50">
          <button
            id="burger-menu"
            className="text-secondary hover:text-primary"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
      </div>

      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a className="text-secondary hover:text-primary block px-3 py-2 rounded-md text-base font-medium">
            <ScrollLink
              to="features"
              className="text-secondary hover:text-primary mx-2 cursor-pointer "
            >
              Features
            </ScrollLink>
          </a>
          <a className="text-secondary hover:text-primary block px-3 py-2 rounded-md text-base font-medium">
            <ScrollLink
              to="pricing"
              className="text-secondary hover:text-primary mx-2 cursor-pointer "
            >
              Pricing
            </ScrollLink>
          </a>
          <a className="text-secondary hover:text-primary block px-3 py-2 rounded-md text-base font-medium">
            <ScrollLink
              to="contact"
              className="text-secondary hover:text-primary mx-2 cursor-pointer"
            >
              Contact
            </ScrollLink>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
