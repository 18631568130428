import React, { useState } from "react";

const Footer = () => {
  return (
    <div>
      <footer class="flex flex-col items-center bg-primary text-center text-surface  dark:text-white">
        <div class="container pt-9">
          <div class="mb-6 flex justify-center space-x-2">
            <a
              href="https://www.linkedin.com/in/vuk-mladenovi%C4%87/"
              target="_blank"
              type="button"
              class="rounded-full bg-transparent p-3 font-medium uppercase leading-normal text-surface transition duration-150 ease-in-out hover:bg-neutral-100 focus:outline-none focus:ring-0 dark:text-white dark:hover:bg-secondary-900"
              data-twe-ripple-init
            >
              <span class="mx-auto [&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                </svg>
              </span>
            </a>
          </div>
        </div>

        <div class="w-full bg-primary p-4 text-center">
          © 2024 Copyright
          <a href="https://wolfmail.services">
            {" "}
            Wolf<span className="font-bold">Mail</span>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
