import React from "react";

function Estimated({
  numberOfTemplates,
  numberOfAdaptations,
  deliveryTime,
  activeTab,
}) {
  // Ensure that the deliveryTime string matches the conditional statements
  let deliveryPrice = 0;
  if (activeTab === "codeOnly") {
    if (deliveryTime === "1 day") {
      deliveryPrice = 109;
    } else if (deliveryTime === "2 days") {
      deliveryPrice = 72;
    } else if (deliveryTime === "5 days") {
      deliveryPrice = 39;
    }
  } else if (activeTab === "codeDesign") {
    if (deliveryTime === "2 days") {
      deliveryPrice = 319;
    } else if (deliveryTime === "3 days") {
      deliveryPrice = 249;
    } else if (deliveryTime === "5 days") {
      deliveryPrice = 159;
    }
  }

  // Ensure that the state variables are initialized correctly
  numberOfTemplates =
    typeof numberOfTemplates === "number" ? numberOfTemplates : 1;
  numberOfAdaptations =
    typeof numberOfAdaptations === "number" ? numberOfAdaptations : 0;

  // Calculate total price based on the number of templates and adaptations
  const totalPrice =
    (numberOfTemplates > 1 ? (numberOfTemplates - 1) * 80 : 0) +
    numberOfAdaptations * 20;

  // Calculate the total price including delivery
  const totalWithDelivery = totalPrice + deliveryPrice; // Calculating totalWithDelivery here

  // Define the selected package based on the activeTab
  let selectedPackage = "";
  if (activeTab === "codeOnly") {
    selectedPackage = "Code Only";
  } else if (activeTab === "codeDesign") {
    selectedPackage = "Design + Code";
  } else if (activeTab === "otherServices") {
    selectedPackage = "Other Services";
  }

  return (
    <div className="container mx-auto px-4 py-16 bg-secondary rounded-lg ">
      <h2 className="text-xl font-bold mb-4 text-center">Estimated Total</h2>
      <p className="text-center  pb-4"> {selectedPackage}</p>
      <div className="flex justify-between items-center mb-2">
        <span>Number of Templates:</span>
        <span>${numberOfTemplates > 1 ? (numberOfTemplates - 1) * 80 : 0}</span>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span>Number of Adaptations:</span>
        <span>${numberOfAdaptations * 20}</span>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span>Delivery Time:</span>
        <span>{deliveryTime}</span>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span>Delivery Price:</span>
        <span>${deliveryPrice}</span>
      </div>
      <hr className="my-2" />
      <div className="flex justify-between items-center">
        <span>Total:</span>
        <span>${totalWithDelivery}</span>
      </div>
    </div>
  );
}

export default Estimated;
