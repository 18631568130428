import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import "./styles.css";
import img1 from "../assets/svg-image-9.svg";
import img2 from "../assets/svg-image-7.svg";
import img3 from "../assets/svg-image-11.svg";
import hero from "../assets/email.png";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const WolfApp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const offset = -100; // Adjust this value as needed
        scroll.scrollTo(element.offsetTop + offset, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://formspree.io/f/mzbnqwdw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          _replyto: "wolfvxerxz@gmail.com",
        }),
      });

      if (response.ok) {
        // Form submission successful
        console.log("Form submitted successfully");
        alert("Form submitted successfully!");
        setFormData({ name: "", email: "", message: "" }); // Reset form fields
      } else {
        // Form submission failed
        console.error("Form submission failed");
        alert("Form submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  window.addEventListener("scroll", function () {
    var scrollAnimations = document.querySelectorAll(".scroll-animation");
    scrollAnimations.forEach(function (element) {
      var elementPosition = element.getBoundingClientRect().top;
      var screenPosition = window.innerHeight / 1.2;
      if (elementPosition < screenPosition) {
        element.classList.add("show");
      }
    });
  });

  return (
    <div>
      <Navbar />

      <header
        id="home"
        className="bg-primary text-white text-center py-40 z-40 bg-img"
      >
        <h1
          className="text-4xl font-extrabold mb-4"
          style={{ color: "#9898a8" }}
        >
          Elevate your email marketing with professionally
          <br />
          <span className="text-white">
            crafted templates that drive engagement:
          </span>
        </h1>
        <ul className="text-gray-500 font-mono mb-10">
          <li>✓ Hand-coded and thoroughly tested</li>
          <li>✓ Seamlessly optimized for all email clients and devices</li>
          <li>
            ✓ Responsive design ensures perfect display on any screen size
          </li>
          <li>✓ Customizable templates to match your brand's identity</li>
          <li>✓ Quick turnaround time to meet your marketing schedule</li>
        </ul>
        <p className="text-xl mb-8"></p>
        <ScrollLink
          to="contact"
          className="bg-button text-white py-4 px-6 rounded-full text-lg cursor-pointer"
        >
          Get in touch
        </ScrollLink>
      </header>

      <Element name="features">
        <section id="features" className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold text-center mb-8">
            What Can We Do For You?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 space-x-22">
            <div className="bg-secondary p-6 rounded-lg shadow-md">
              <img
                src={img1}
                className="w-16 h-16 text-gray-500 dark:text-gray-400 mb-3 mx-auto flex justify-center"
                alt="Email Design"
              />
              <h3 className="text-2xl font-bold text-center text-primary mb-2">
                Email Design
              </h3>
              <p className="text-gray-400 text-center">
                Creating beautiful emails for your promotions, offers and
                campaigns.
              </p>
            </div>
            <div className="bg-secondary p-6 rounded-lg shadow-md">
              <img
                src={img2}
                className="w-16 h-16 text-gray-500 dark:text-gray-400 mb-3 mx-auto flex justify-center"
                alt="Hand Coding Emails"
              />
              <h3 className="text-2xl font-bold text-center text-primary mb-2">
                Hand Coding Emails
              </h3>
              <p className="text-gray-400 text-center">
                Hand coding your html emails with clean, structured and well
                commented code
              </p>
            </div>
            <div className="bg-secondary p-6 rounded-lg shadow-md">
              <img
                src={img3}
                className="w-16 h-16 text-gray-500 dark:text-gray-400 mb-3 mx-auto flex justify-center"
                alt="Email Testing"
              />
              <h3 className="text-2xl font-bold text-center text-primary mb-2">
                Email Testing
              </h3>
              <p className="text-gray-400 text-center">
                With "Email On Acid", we test emails and share the test results
                with you
              </p>
            </div>
          </div>
        </section>
      </Element>

      <div className="flex flex-col md:flex-row pt-20 p-20 scroll-animation">
        <section className="bg-primary w-full md:w-1/2 text-left text-white pt-20 scroll-animation">
          <h1 className=" text-3xl font-bold mb-4" style={{ color: "#9898a8" }}>
            Emails that are well optimized.
            <br />
          </h1>
          <p className="text-lg text-left mb-8">
            - Emails we create will be responsive to small screens and mobile
            devices (if clients request so).
            <br />
            <br />
            - We also ensure that emails are properly optimized for dark-mode
            email client apps.
            <br />
            <br />
            - Overall, the rendering quality of the HTML emails we develop and
            deliver to you will be maximized, with all necessary fallback
            solutions.
            <br />
            <br />- We thoroughly test each email using the "Email on Acid"
            testing service.
          </p>
        </section>
        <div className="w-full md:w-1/2 scroll-animation">
          <img src={hero} className="mx-auto" alt="Email" />
        </div>
      </div>

      {/* Pricing Section */}
      <Element name="pricing">
        <section
          id="pricing"
          className="container mx-auto px-6 py-16 scroll-animation"
        >
          <h2 className="text-4xl font-bold text-center mb-8 text-white">
            Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-secondary p-8 rounded-lg shadow-lg text-center bg-card-section border border-solid border-1 border-[#1e1e22]">
              <h3 className="text-2xl font-semibold text-white mb-4">
                Code Only
              </h3>
              <p className="text-gray-400 mb-2">Starting at</p>
              <p className="text-4xl font-bold text-white mb-6">$39</p>
              <p className="text-gray-300 mb-6">
                Convert your designs into responsive HTML email templates with
                precision.
              </p>
              <button
                className="bg-button text-white py-3 px-6 rounded-md transition-colors bg-button duration-300"
                onClick={() =>
                  navigate("/Order", { state: { activeTab: "codeOnly" } })
                }
              >
                Order Now
              </button>
            </div>
            <div className="bg-secondary p-8 rounded-lg shadow-lg text-center bg-card-section border border-solid border-1 border-[#1e1e22]">
              <h3 className="text-2xl font-semibold text-white mb-4">
                Design + Code
              </h3>
              <p className="text-gray-400 mb-2">Starting at</p>
              <p className="text-4xl font-bold text-white mb-6">$159</p>
              <p className="text-gray-300 mb-6">
                Custom-designed and coded email templates to match your brand
                and goals.
              </p>
              <button
                className="bg-button text-white py-3 px-6 rounded-md transition-colors bg-button duration-300"
                onClick={() =>
                  navigate("/Order", {
                    state: { activeTab: "codeDesign", duration: "2 days" },
                  })
                }
              >
                Order Now
              </button>
            </div>
            <div className="bg-secondary p-8 rounded-lg shadow-lg text-center bg-card-section border border-solid border-1 border-[#1e1e22]">
              <h3 className="text-2xl font-semibold text-white mb-4">
                Other Services
              </h3>
              <p className="text-gray-400 mb-2">Starting at</p>
              <p className="text-4xl font-bold text-white mb-6">$10/hour</p>
              <p className="text-gray-300 mb-6">
                Hire our experts for email campaigns, automations, and landing
                pages.
              </p>
              <ScrollLink
                to="contact"
                className="bg-button text-white py-4 px-6 rounded-full text-lg cursor-pointer"
           
              >
                Get in touch
              </ScrollLink>
            </div>
          </div>
        </section>
      </Element>

      {/* Contact Section */}
      <section
        id="contact"
        className="container mx-auto px-4 py-16 scroll-animation"
      >
        <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
        <form
          id="contactForm"
          className="max-w-lg mx-auto bg-secondary p-6 rounded-lg shadow-md"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label className="block text-secondary mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="w-full p-2 rounded bg-gray-700 text-gray-300"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-secondary mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="w-full p-2 rounded bg-gray-700 text-gray-300"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-secondary mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="w-full p-2 rounded bg-gray-700 text-gray-300"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button
            className="bg-button text-white py-2 px-4 rounded"
            type="submit"
          >
            Send Message
          </button>
        </form>
      </section>
      <button className="scroll-to-top" onClick={scrollToTop}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white iconi"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 8"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
          />
        </svg>
      </button>
      {/* Footer */}
      <Footer/>
    </div>
  );
};

export default WolfApp;
