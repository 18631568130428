import React, { useState, useEffect  } from "react";
import Navbar from "./navbar";
import Footer from "./footer";

import leftArrow from "../assets/left-arrow.png";
import rightArrow from "../assets/right-arrow.png";
import Estimated from "./order_options/Estimate";
import { useLocation } from "react-router-dom";

function Tab({ label, onClick, isActive }) {
  return (
    <li className="flex-2 text-center">
      <a
        href="#"
        className={`text-lg sm:text-xl font-bold text-primary  mb-2 ${
          isActive ? "underline && text-rose-500" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {label}
      </a>
    </li>
  );
}

function TabContent({ id, isActive, children }) {
  return isActive ? (
    <div id={id} className="text-center">
      {children}
    </div>
  ) : null;
}

function Order() {

 
  const location = useLocation();
  const activeTabFromLink = location.state
    ? location.state.activeTab
    : "codeOnly";
  const durationFromLink = location.state ? location.state.duration : null;

  const defaultDeliveryTime =
    activeTabFromLink === "codeDesign" ? "2 days" : "1 day";

  const [showTab, setShowTab] = useState({
    codeOnly: activeTabFromLink === "codeOnly" || !activeTabFromLink,
    codeDesign: activeTabFromLink === "codeDesign",
    otherServices: activeTabFromLink === "otherServices",
  });

  const [deliveryTime, setDeliveryTime] = useState(defaultDeliveryTime);
  const [numberOfTemplates, setNumberOfTemplates] = useState(1);
  const [numberOfAdaptations, setNumberOfAdaptations] = useState(0);
  const [selectedEmailSoftware, setSelectedEmailSoftware] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleTabClick = (tab) => {
    setShowTab({
      codeOnly: tab === "codeOnly",
      codeDesign: tab === "codeDesign",
      otherServices: tab === "otherServices",
    });

    setDeliveryTime(tab === "codeOnly" ? "1 day" : "2 days");
  };

  const handleDeliveryTimeChange = (time) => {
    setDeliveryTime(time);
  };

  const incrementTemplates = () => {
    setNumberOfTemplates((prev) => prev + 1);
  };

  const decrementTemplates = () => {
    setNumberOfTemplates((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const incrementAdaptations = () => {
    setNumberOfAdaptations((prev) => prev + 1);
  };

  const decrementAdaptations = () => {
    setNumberOfAdaptations((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [totalWithDelivery, setTotalWithDelivery] = useState(0);

  const handleEmailSoftwareChange = (event) => {
    const value = event.target.value;
    setSelectedEmailSoftware((prev) =>
      prev.includes(value)
        ? prev.filter((software) => software !== value)
        : [...prev, value]
    );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const totalPrice =
      (numberOfTemplates > 1 ? (numberOfTemplates - 1) * 80 : 0) +
      numberOfAdaptations * 20;
    const deliveryPrice = showTab.codeOnly
      ? getCodeOnlyPrice()
      : getCodeDesignPrice();
    const totalWithDelivery = totalPrice + deliveryPrice;
    setTotalWithDelivery(totalWithDelivery);
  }, [numberOfTemplates, numberOfAdaptations, showTab, deliveryTime]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let bodyData;
      if (showTab.otherServices) {
        bodyData = {
          name: formData.name,
          email: formData.email,
          message: formData.message,
          _replyto: "wolfvxerxz@gmail.com",
        };
      } else {
        bodyData = {
          name: formData.name,
          email: formData.email,
          message: formData.message,
          deliveryTime: deliveryTime,
          numberOfTemplates: numberOfTemplates,
          numberOfAdaptations: numberOfAdaptations,
          selectedEmailSoftware: selectedEmailSoftware,
          totalWithDelivery: totalWithDelivery,
          _replyto: "wolfvxerxz@gmail.com",
        };
      }

      const response = await fetch("https://formspree.io/f/mzbnqwdw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        alert("Form submitted successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        console.error("Form submission failed");
        alert("Form submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const getCodeDesignPrice = () => {
    switch (deliveryTime) {
      case "2 days":
        return 319;
      case "3 days":
        return 249;
      case "5 days":
        return 159;
      default:
        return 0;
    }
  };

  const getCodeOnlyPrice = () => {
    switch (deliveryTime) {
      case "1 day":
        return 109;
      case "2 days":
        return 72;
      case "5 days":
        return 39;
      default:
        return 0;
    }
  };

  const deliveryTimes = showTab.codeOnly
    ? ["1 day", "2 days", "5 days"]
    : ["2 days", "3 days", "5 days"];

  return (
    <div>
      <Navbar />
      <header className="bg-primary text-white text-center py-20 bg-img">
        {showTab.codeOnly && (
          <h1 className="text-4xl font-extrabold mb-4">
            From PSD to HTML
            <br />
            <span className="text-white text-xl">
              Send us your design and instructions and receive high quality HTML
              template
            </span>
          </h1>
        )}
        {showTab.codeDesign && (
          <h1 className="text-4xl font-extrabold mb-4">
            Responsive Email Design that Converts
            <br />
            <span className="text-white text-xl">
              Get ROI-driven custom designed email templates and landing pages
              for your Email Marketing campaign
            </span>
          </h1>
        )}
        {showTab.otherServices && (
          <h1 className="text-4xl font-extrabold mb-4">
            Professional Email Marketing Services
            <br />
            <span className="text-white text-xl">
              Contact us for additional services such as campaigns, automation,
              consulting, and more
            </span>
          </h1>
        )}
      </header>

      <section id="features" className="container mx-auto px-6 py-16">
        <div className="border w-full md:w-2/3 mx-auto px-2 rounded-xl py-2 flex justify-center">
          <ul className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-20">
            <Tab
              label="Code Only"
              onClick={() => handleTabClick("codeOnly")}
              isActive={showTab.codeOnly}
            />
            <Tab
              id="Code+Design"
              label="Design + Code"
              onClick={() => handleTabClick("codeDesign")}
              isActive={showTab.codeDesign}
            />
            <Tab
              label="Other Services"
              onClick={() => handleTabClick("otherServices")}
              isActive={showTab.otherServices}
            />
          </ul>
        </div>
      </section>

      <section>
        <div className="bg-secondary">
          <TabContent id="codeOnly" isActive={showTab.codeOnly}>
            {/* Code Only Content */}
          </TabContent>
          <TabContent id="codeDesign" isActive={showTab.codeDesign}>
            {/* Code Design Content */}
          </TabContent>
        </div>
      </section>

      {!showTab.otherServices && (
        <section className="container mx-auto px-6 py-16">
          <h2 className="text-xl font-bold mb-4 text-center">Delivery Time</h2>
          <div className="mb-4 text-center">
            {deliveryTimes.map((time, index) => (
              <button
                key={index}
                onClick={() => handleDeliveryTimeChange(time)}
                className={`px-4 py-2 m-2 rounded ${
                  deliveryTime === time
                    ? "bg-rose-500 text-white"
                    : "bg-secondary text-primary border border-primary"
                }`}
              >
                {time}
              </button>
            ))}
          </div>
          <h2 className="text-xl font-bold mb-4 text-center">
            Number of Templates
          </h2>
          <div className="flex items-center justify-center mb-4">
            <img
              src={leftArrow}
              alt="Decrement"
              className="cursor-pointer w-6 h-6"
              onClick={decrementTemplates}
            />
            <span className="mx-4">{numberOfTemplates}</span>
            <img
              src={rightArrow}
              alt="Increment"
              className="cursor-pointer w-6 h-6"
              onClick={incrementTemplates}
            />
          </div>
          <h2 className="text-xl font-bold mb-4 text-center">
            Number of Adaptations
          </h2>
          <div className="flex items-center justify-center mb-4">
            <img
              src={leftArrow}
              alt="Decrement"
              className="cursor-pointer w-6 h-6"
              onClick={decrementAdaptations}
            />
            <span className="mx-4">{numberOfAdaptations}</span>
            <img
              src={rightArrow}
              alt="Increment"
              className="cursor-pointer w-6 h-6"
              onClick={incrementAdaptations}
            />
          </div>
          <h2 className="text-xl font-bold mb-4 text-center">
            Email Software Integration
          </h2>
          <div className="flex flex-wrap justify-center">
            <div className="mb-4 flex flex-col items-center">
              {[
                "MailChimp",
                "Constant Contact",
                "AWeber",
                "CampaignMonitor",
                "GetResponse",
                "Other",
              ].map((software, index) => (
                <label key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    value={software}
                    checked={selectedEmailSoftware.includes(software)}
                    onChange={handleEmailSoftwareChange}
                    className="mr-2"
                  />
                  {software}
                </label>
              ))}
            </div>
          </div>
          <div id="pricing" className="m-auto flex-1 sticky pt-20 max-w-96">
            <Estimated
              className="w-96"
              activeTab={showTab.codeOnly ? "codeOnly" : "codeDesign"}
              numberOfTemplates={numberOfTemplates}
              numberOfAdaptations={numberOfAdaptations}
              deliveryTime={deliveryTime}
              codeOnlyPrice={getCodeOnlyPrice()}
              codeDesignPrice={getCodeDesignPrice()}
              totalWithDelivery={totalWithDelivery}
            />
          </div>
        </section>
      )}

      {(showTab.otherServices || showTab.codeOnly || showTab.codeDesign) && (
        <section id="otherServices" className="container mx-auto px-4 py-16">
          <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
          <form
            id="contact"
            className="max-w-lg mx-auto bg-secondary p-6 rounded-lg shadow-md"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label className="block text-secondary mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="w-full p-2 rounded bg-gray-700 text-gray-300"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-secondary mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-2 rounded bg-gray-700 text-gray-300"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-secondary mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="w-full p-2 rounded bg-gray-700 text-gray-300"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button
              className="bg-button text-white py-2 px-4 rounded"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </section>
      )}

      <Footer/>
    </div>
  );
}

export default Order;
